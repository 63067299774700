export default {
  methods: {
    getGCFormatLogoPath(name) {
      const map = {
        POKEMON: '/img/logos/gaming-club/logo_pokemon.svg',
        MTG: '/img/logos/gaming-club/logo_mtg-small.png',
        DIGIMON: '/img/logos/gaming-club/logo_digimon.png',
        YUGIOH: '/img/logos/gaming-club/logo_yu-gi-oh.png',
        'FLESH&BLOOD': '/img/logos/gaming-club/logo_flesh-and-blood.png',
        ONEPIECE: '/img/logos/gaming-club/logo_one-piece.png',
        VANGUARD: '/img/logos/gaming-club/logo_vanguard.png',
      }

      return map[name]
    },
  }
}
