
  import Tooltip from '~/components/general/elements/Tooltip/Tooltip.vue'

  export default {
    name: 'GCXIcon',
    components: { Tooltip },
    functional: true,
    props: {
      x2: Boolean,
      x3: Boolean
    }
  }
