
  import Row from '~/components/layout/Row'
  import {extractFunctionalClass} from '~/services/Helpers'

  export default {
    name: 'GCLabelValue',
    functional: true,
    components: { Row },
    props: {
      label: String,
      light: Boolean,
      alignHorizontal: {
        type: String,
        default: 'start'
      },
      valueNoWrap: Boolean,
    },
    getClass(light, staticClass, dynamicClass) {
      const classObject = extractFunctionalClass(staticClass, dynamicClass)
      classObject.GCLabelValue = true
      classObject['GCLabelValue--light'] = light

      return classObject
    }
  }
