
  import isEmpty from 'lodash/isEmpty'
  import DateSign from '~/components/general/elements/DateSign'
  import GCLabelValue from '~/components/gaming-club/elements/GCLabelValue'
  import GCXIcon from '~/components/gaming-club/elements/GCXIcon'
  import DirectLink from '~/components/general/elements/DirectLink'
  import Row from '~/components/layout/Row'
  import {extractFunctionalClass} from '~/services/Helpers'

  export default {
    name: 'Tournament',
    components: { GCXIcon, GCLabelValue, Row, DateSign, DirectLink },
    functional: true,
    props: {
      id: Number,
      title: String,
      date: String,
      icon: String,
      registrationTime: String,
      startTime: String,
      price: String,
      prizeDescription: String,
      description: String,
      link: String,
      expanded: Boolean,
      isEnrolled: Boolean,
      x2: Boolean,
      x3: Boolean,
      hasSignIn: Boolean
    },
    extractFunctionalClass,
    isEmpty (value) {
      return isEmpty(value)
    },
    onExpandedClick (id, listeners) {
      if ('expand' in listeners) {
        listeners.expand(id)
      }
    },
    onEnrollClick (id, listeners) {
      if ('enroll' in listeners) {
        listeners.enroll(id)
      }
    },
    onDisenrollClick (id, listeners) {
      if ('disenroll' in listeners) {
        listeners.disenroll(id)
      }
    }
  }
