
  import { dayMonth, dayShortcut } from '@/services/DateService'

  export default {
    name: 'DateSign',
    functional: true,
    components: {},
    props: {
      date: {
        type: String,
        required: true
      }
    },
    getDay (date) {
      return dayShortcut(date)
    },
    getFormattedDate (date) {
      return dayMonth(date)
    }
  }
